import { EventUpdateIncentivePoint } from '../utils/event'
import { IncentivePointForPromo } from '../utils/incentivePoint'
import { mapState } from 'vuex'

export default {
  components: {
    IncentivePoint: () => import(/* webpackChunkName: "incentive_point" */'public/src/pages/common/addOnItem/comps/IncentivePoint.vue'),
  },

  data() {
    return {
      // 标签
      tags: [],
      // 气泡
      bubble: {},
    }
  },

  computed: {
    ...mapState(['abtInfo'])
  },

  mounted() {
    this.initIncentivePoint()
    window.addEventListener('beforeunload', this.destoryIncentivePoint.bind(this))
  },

  beforeDestroy() {
    this.destoryIncentivePoint()
  },
  
  methods: {
    /**
     * 初始化利诱
     */
    async initIncentivePoint() {
      this.IncentivePointForPromo = new IncentivePointForPromo({
        abtInfo: this.abtInfo
      })

      // 初始化标签数据
      this.tags = (await this.IncentivePointForPromo.getTags({
        showBubble: false,
      })).tags ?? []

      // 监听加购事件
      EventUpdateIncentivePoint.subscribe({
        once: false,
        callback: async ({ showBubble }) => {
          const data = await this.IncentivePointForPromo.getTags({ showBubble })
          this.tags = data.tags ?? []
          // 线上气泡优先级最大
          if (showBubble) {
            this.bubble = data.bubble[0] ?? {}
          } else {
            this.bubble = {}
          }
        }
      })
      // [TODO] FOR DEBUGE
      window.__init_incentive_point_update__ = function(showBubble = true) {
        EventUpdateIncentivePoint.notify({
          showBubble,
        })
      }
    },

    /**
     * 利诱清理工作
     */
    destoryIncentivePoint() {
      // 清除缓存
      this.IncentivePointForPromo && this.IncentivePointForPromo.destroy()
      // 停止监听事件
      EventUpdateIncentivePoint.remove()
    },
  },
}

